import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
// import { createBrowserHistory } from "history";
// const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
/* var clickPluginInstance = new ClickAnalyticsPlugin();
   var clickPluginConfig = {
     autoCapture: true
}; */
var appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=9fe63f9f-a58d-4987-b356-773d6be37749;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/",
    // *** If you're adding the Click Analytics plug-in, delete the next line. ***
    extensions: [reactPlugin],
    // *** Add the Click Analytics plug-in. ***
    // extensions: [reactPlugin, clickPluginInstance],
    // extensionConfig: {
    //   [reactPlugin.identifier]: { history: browserHistory },
    // *** Add the Click Analytics plug-in. ***
    // [clickPluginInstance.identifier]: clickPluginConfig
    // }
    // enableAutoRouteTracking: true
  },
});
appInsights.loadAppInsights();
export default appInsights;
