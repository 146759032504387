import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ReactPlayer from "react-player";
import AppInsights from "../AppInsights";
import { useSearchParams } from "react-router-dom";

export default function Link() {
  const [link, setLink] = useState("");
  const [playerlink, setPlayerLink] = useState("");
  const [url, setUrl] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    let sharedURL = searchParams.get("url");
    let sharedText = searchParams.get("text");

    if (sharedText && sharedText.length > 0) {
      playFromLink(sharedText);
    }
    else if (sharedURL && sharedURL.length > 0) {
      playFromLink(sharedURL);
    } 
  }, [searchParams]);

  const playFromLink = (playURL) => {
    setLink(playURL);
    setPlayerLink(playURL);
    setUrl(encodeURIComponent(btoa(playURL)));
    AppInsights.trackEvent({ name: "shared", properties: { url: playURL } });
  };

  const goClick = () => {
    setUrl(encodeURIComponent(btoa(link)));
    setPlayerLink(link);
    AppInsights.trackEvent({ name: "link", properties: { url: link } });
  };

  const handleInputChange = (e) => {
    setLink(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      goClick();
    }
  };

  const playerError = (e, x) => {
    console.log(e);
    console.log(x);
  };

  return (
    <Box
      style={{
        padding: 8,
      }}
    >
      {playerlink ? (
        <ReactPlayer
          controls={true}
          url={playerlink}
          style={{
            maxWidth: "100%",
          }}
          onError={(e, x) => playerError(e, x)}
        />
      ) : (
        ""
      )}
      <Box
        style={{
          display: "flex",
          alignContent: "space-between",
          marginTop: 8,
        }}
      >
        <TextField
          id="url"
          label=""
          style={{
            flexGrow: 1,
          }}
          onChange={(x) => handleInputChange(x)}
          onKeyUp={(e) => handleKeyUp(e)}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: 10,
          }}
          onClick={goClick}
          type="submit"
        >
          Load
        </Button>
      </Box>
      <Box
        style={{
          marginTop: 10,
        }}
      >
        <Typography variant="body1" color="#fff">
          {url ? (
            <a
              color="#fff"
              href={`https://thankful-forest-098f23800.4.azurestaticapps.net/${url}`}
            >
              Link
            </a>
          ) : (
            ""
          )}
        </Typography>
      </Box>
    </Box>
  );
}
