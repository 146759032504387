import { useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AppInsights from "./AppInsights";
import Player from "./Components/Player";
import NotFound from "./Components/NotFound";

function App() {
  const { item } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [urlIsValid, setUrlIsValid] = useState(false);
  const [url, setUrl] = useState();

  const item2 = searchParams.get("item");

  useEffect(() => {

   

    try {
      setUrl(atob(decodeURIComponent(item ?? item2)));
      setUrlIsValid(true);
    } catch (e) {
      setUrlIsValid(false);
      console.log(e);
    }

    AppInsights.trackEvent({ name: "watch", properties: { url: url } });
  }, []);


if (!item && !item2) {
  return <NotFound />;
}

  return <>{urlIsValid && <Player url={url} />}</>;
}

export default App;
