import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Link from "./Components/Link";
import NotFound from "./Components/NotFound";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const router = createBrowserRouter([
 
  {
    path: "/link",
    element: <Link />,
  },
  {
    path: "/:item",
    element: <App />,
  },
  {
    path: "*",
    element: <App />,
  },
]);

const theme = createTheme({
  palette:{
    mode: 'dark'
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);


serviceWorkerRegistration.register();